import QuickActionBarItemComponent from '@wix/thunderbolt-elements/src/components/QuickActionBarItem/viewer/QuickActionBarItem';


const QuickActionBarItem = {
  component: QuickActionBarItemComponent
};


export const components = {
  ['QuickActionBarItem']: QuickActionBarItem
};

